// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  filename: 'environment.facilities-66658978.ts',
  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: false,
    usernamePassword: true,
  },

  firebaseConfig: {
    apiKey: "AIzaSyCcqyn_Q9iwJX5snXJXQKhNKUNw1mc2k0I",
    authDomain: "facilities-66658978.firebaseapp.com",
    databaseURL: "https://facilities-66658978-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-66658978",
    storageBucket: "facilities-66658978.appspot.com",
    messagingSenderId: "111240553382",
    appId: "1:111240553382:web:d4dab2381d581bdacc6567"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-66658978.cloudfunctions.net',

  // nocoDbConfig: {
  //   // httpServer: 'http://localhost:8080',
  //   // httpServer: 'http://spot.local:8080',
  //   httpServer: 'https://mobilitymojo-spot.com',
  //   user: 'test@mobilitymojo.com',
  //   password: 'test@mobilitymojo.com',
  //   authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
  //   projectTitle: 'products-playpen',
  //   // projectTitle: '2024 - Grosvenor and The Crown Estate',
  // },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,

  },

  preview: {

    // fireworksEnabled: true,
    // reportGeneration2: true,
    // clusterSupport: true,
  },



};
