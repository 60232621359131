


export class BrowserAppEvaluationToolInfo {

  public version = '2023.12.7.4';

  constructor() {
  }

}
